var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"blog",attrs:{"id":"blog"}},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"post"},[_vm._m(1),_c('div',{staticClass:"post-content"},[_c('div',{staticClass:"post-title"},[_c('router-link',{attrs:{"to":"/blog-single","target":"_blank"}},[_c('h4',[_vm._v("Relax Your Body")])])],1),_vm._m(2),_c('ul',{staticClass:"post-info list-unstyled"},[_c('li',{staticClass:"pull-left"},[_c('router-link',{staticClass:"post-more",attrs:{"to":"/blog-single","target":"_blank"}},[_vm._v("Read more"),_c('i',{staticClass:"fa fa-angle-double-right"})])],1)]),_vm._m(3)])])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"post"},[_vm._m(4),_c('div',{staticClass:"post-content"},[_c('div',{staticClass:"post-title"},[_c('router-link',{attrs:{"to":"/blog-single","target":"_blank"}},[_c('h4',[_vm._v("Yoga For Children")])])],1),_vm._m(5),_c('ul',{staticClass:"post-info list-unstyled"},[_c('li',{staticClass:"pull-left"},[_c('router-link',{staticClass:"post-more",attrs:{"to":"/blog-single","target":"_blank"}},[_vm._v("Read more"),_c('i',{staticClass:"fa fa-angle-double-right"})])],1)]),_vm._m(6)])])])]),_c('div',{staticClass:"my-btn"},[_c('router-link',{staticClass:"main-btn",attrs:{"to":"/Blog-sidebar","target":"_blank"}},[_c('span',[_vm._v("View All")])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title text-center"},[_c('span',{staticClass:"separator"},[_c('i',{staticClass:"flaticon-chakra"})]),_c('h2',[_vm._v("Our Latest Articles")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://via.placeholder.com/800x560","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-text"},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-date"},[_c('span',[_vm._v("22")]),_vm._v("Jan")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"https://via.placeholder.com/800x560","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-text"},[_c('p',[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut... ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-date"},[_c('span',[_vm._v("22")]),_vm._v("Jan")])
}]

export { render, staticRenderFns }